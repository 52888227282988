import React, { useState, useEffect } from 'react';
import { useAuth } from '../context/AuthContext';
import CommonerDashboard from './CommonerDashboard';
import AdventurerDashboard from './AdventurerDashboard';
import AdminDashboard from './AdminDashboard';
import './Dashboard.css';

const Dashboard = () => {
  const { currentUser, loading } = useAuth();
  const [messages, setMessages] = useState([]);
  const [lastSession, setLastSession] = useState('');

  useEffect(() => {
    // Fetch messages and last session info from server
    // This will be implemented later
    const fetchDashboardData = async () => {
      if (currentUser) {
        // Mock data for now
        setMessages([
          { id: 1, from: 'Game Master', content: 'Welcome to First Veil!', date: new Date().toISOString() }
        ]);
        setLastSession('The party ventured into the Crimson Mist and discovered ancient ruins...');
      }
    };

    fetchDashboardData();
  }, [currentUser]);

  if (loading) {
    return (
      <div className="dashboard-loading">
        <div className="loading-spinner"></div>
        <p>Loading your realm data...</p>
      </div>
    );
  }

  if (!currentUser) {
    return null; // User not authenticated, should be redirected by AuthContext
  }

  // Render different dashboard based on user role
  const renderDashboard = () => {
    switch (currentUser.role) {
      case 'admin':
        return <AdminDashboard />;
      case 'adventurer':
        return (
          <AdventurerDashboard 
            messages={messages} 
            lastSession={lastSession}
          />
        );
      case 'commoner':
      default:
        return <CommonerDashboard />;
    }
  };

  return (
    <div className="dashboard-container">
      <header className="dashboard-header">
        <h1>Welcome, {currentUser.displayName}</h1>
        <div className="user-info">
          <div className="user-avatar">
            {currentUser.avatar ? (
              <img src={currentUser.avatar} alt={currentUser.displayName} />
            ) : (
              <div className="avatar-placeholder">
                {currentUser.displayName.charAt(0).toUpperCase()}
              </div>
            )}
          </div>
          <div className="user-role">
            <span className={`role-badge role-${currentUser.role}`}>
              {currentUser.role.charAt(0).toUpperCase() + currentUser.role.slice(1)}
            </span>
          </div>
        </div>
      </header>

      <main className="dashboard-content">
        {renderDashboard()}
      </main>
    </div>
  );
};

export default Dashboard; 