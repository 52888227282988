import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import LoginPage from './login-page/LoginPage';
import Dashboard from './dashboard/Dashboard';
import { AuthProvider, useAuth } from './context/AuthContext';
import './App.css';

// Main App component
function App() {
  return (
    <Router>
      <AuthProvider>
        <AppContent />
      </AuthProvider>
    </Router>
  );
}

// App content that depends on authentication state
function AppContent() {
  const { currentUser, loading } = useAuth();
  const [showDashboard, setShowDashboard] = useState(false);
  
  // Effect to handle transition from login to dashboard
  useEffect(() => {
    if (currentUser) {
      // Small delay to allow for smooth transition
      const timer = setTimeout(() => {
        setShowDashboard(true);
      }, 300);
      
      return () => clearTimeout(timer);
    } else {
      setShowDashboard(false);
    }
  }, [currentUser]);
  
  if (loading) {
    return (
      <div className="loading-screen">
        <div className="loading-spinner"></div>
        <p>Entering the realm...</p>
      </div>
    );
  }
  
  return (
    <>
      {!currentUser && <LoginPage />}
      {showDashboard && currentUser && <Dashboard />}
    </>
  );
}

export default App;
