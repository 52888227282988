import React, { useState } from 'react';
import { useAuth } from '../context/AuthContext';
import LoginBackground from './LoginBackground';
import './LoginPage.css';

const LoginPage = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const { login } = useAuth();

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    try {
      setError('');
      setLoading(true);
      await login(email, password);
      // No navigation here - we'll handle that in the AuthContext
    } catch (err) {
      setError('The arcane sigils you provided are incorrect. Try again, adventurer.');
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  // Rune symbols for decorative elements
  const runeSymbols = {
    topLeft: '᛭',
    topRight: 'ᚦ',
    bottomLeft: 'ᚨ',
    bottomRight: 'ᛟ'
  };

  return (
    <div className="login-page-container">
      {/* Animated background */}
      <LoginBackground />
      
      {/* Login box */}
      <div className="login-box">
        {/* Decorative rune symbols */}
        <span className="rune-symbol top-left">{runeSymbols.topLeft}</span>
        <span className="rune-symbol top-right">{runeSymbols.topRight}</span>
        <span className="rune-symbol bottom-left">{runeSymbols.bottomLeft}</span>
        <span className="rune-symbol bottom-right">{runeSymbols.bottomRight}</span>
        
        <div className="login-title">
          <h1>First Veil</h1>
          <h2>Enter the Realm</h2>
        </div>
        
        <form className="login-form" onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="email">Arcane Sigil (Email)</label>
            <input
              type="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              placeholder="Enter your arcane sigil"
            />
          </div>
          
          <div className="form-group">
            <label htmlFor="password">Mystical Key (Password)</label>
            <input
              type="password"
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              placeholder="Enter your mystical key"
            />
          </div>
          
          {error && <div className="error-message">{error}</div>}
          
          <button
            type="submit"
            className="login-button"
            disabled={loading}
          >
            {loading ? 'Casting Spell...' : 'Begin Journey'}
          </button>
        </form>
        
        <div className="signup-section">
          <span className="signup-text">New to the realm?</span>
          <button 
            className="signup-link"
            onClick={() => alert("Signup functionality will be implemented in the next phase.")}
          >
            Forge Your Destiny
          </button>
        </div>
      </div>
    </div>
  );
};

export default LoginPage; 